<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <!--<CIcon name="logo" height="48" alt="Logo"/>-->
      <span class="font-dosis-title" style="font-size: 28px;">Eclerk</span>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <!--<CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/abonnement/creation">
        Votre abonnement
        </CHeaderNavLink>
      </CHeaderNavItem>-->
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CDropdown
        inNav
        class="c-header-nav-items"
        placement="bottom-end"
        add-menu-classes="pt-0"
      >
        <template #toggler>
          <CHeaderNavItem class="d-md-down-none mx-2">
            <CHeaderNavLink>
              <CIcon name="cil-user" />
            </CHeaderNavLink>
          </CHeaderNavItem>
        </template>

        <CDropdownHeader
          tag="div"
          class="text-center"
          color="light"
        >
          <strong>Paramètres</strong>
        </CDropdownHeader>
        <CDropdownItem to="/abonnement/votre-profil">
          <CIcon name="cil-settings" /> Modifier votre profil
        </CDropdownItem>
        <CDropdownItem to="/abonnement/votre-mot-de-passe">
            <CIcon name="cil-shield-alt"/> Modifier votre mot de passe
        </CDropdownItem>
        <CDropdownItem @click="logout">
          <CIcon name="cil-lock-locked" /> Se déconnecter
        </CDropdownItem>
      </CDropdown>
    </CHeaderNav>
  </CHeader>
</template>

<script>

export default {
  name: 'SubscriptionHeader',
  methods: {
   logout: function () {
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/pages/login')
      })
    },
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
