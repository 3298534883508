<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none"> 

      <span class="font-dosis-title" style="font-size: 28px;">Eclerk</span>
    </CSidebarBrand>

    <CSidebarNav>
      <CSidebarNavItem
        name="Votre abonnement" to="/abonnement/creation"
        icon='cil-euro'>
      </CSidebarNavItem>
      <CSidebarNavItem
        name="Votre entreprise" to="/abonnement/votre-entreprise"
        icon='cil-settings'>
      </CSidebarNavItem>
    </CSidebarNav>
    
    

  </CSidebar>
</template>

<script>
import userMixins from '@/mixins/userMixins'

export default {
  name: 'SubscriptionSidebar',
  mixins: [userMixins],
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    },
  },
}
</script>
